import cargoFetch from '@cargo/fetch';
import _ from 'lodash';
import { PENDING, FULFILLED, REJECTED } from "../middleware/api";
import { API_ORIGIN, API_PROXY_ORIGIN, C2_API_ORIGIN, AUTH_ORIGIN } from "@cargo/common";
import getSlug from "@cargo/slug-generator";
import * as Sentry from "@sentry/browser";

const existingBaseRoutes = ['templates', 'deleted', 'account', 'logout', 'login', 'information', 'rates', 'jobs', 'students', 'terms', 'privacy', 'copyright-issues', 'data-processing-agreement', 'fonts'];

const RESTfulActionTypes = {
	AUTHENTICATE_USER 			: 'AUTHENTICATE_USER',
	LOGOUT_USER 				: 'LOGOUT_USER',
	FETCH_ACCOUNT				: 'FETCH_ACCOUNT',
	FETCH_COLLAB 				: 'FETCH_COLLAB',
	FETCH_FOLDERS				: 'FETCH_FOLDERS',
	FETCH_DELETED_SITES			: 'FETCH_DELETED_SITES',
	FETCH_TEMPLATES				: 'FETCH_TEMPLATES',
	CREATE_FOLDER				: 'CREATE_FOLDER',
	UPDATE_FOLDER				: 'UPDATE_FOLDER',
	DELETE_FOLDER				: 'DELETE_FOLDER',
	SAVE_FOLDER_SORT			: 'SAVE_FOLDER_SORT',
	UPDATE_SITE					: 'UPDATE_SITE',
	DELETE_SITE					: 'DELETE_SITE',
	DELETE_ALL_SITES			: 'DELETE_ALL_SITES',
	RESTORE_SITE				: 'RESTORE_SITE',
	DUPLICATE_SITE				: 'DUPLICATE_SITE',
	CONFIRM_DUPLICATION			: 'CONFIRM_DUPLICATION',
	ADD_CLONE_TO_FOLDER			: 'ADD_CLONE_TO_FOLDER',
	LEAVE_SITE					: 'LEAVE_SITE',
	UPDATE_USER_META			: 'UPDATE_USER_META',
	UPDATE_ACCOUNT				: 'UPDATE_ACCOUNT',
	FETCH_SITE_SUBSCRIPTIONS	: 'FETCH_SITE_SUBSCRIPTIONS',
	TRANSFER_SITE_SUBSCRIPTION	: 'TRANSFER_SITE_SUBSCRIPTION',
	FETCH_COMMUNITY_PERIODS		: 'FETCH_COMMUNITY_PERIODS',
	FETCH_INUSE_SITES			: 'FETCH_INUSE_SITES',
	FETCH_FOLDER_SITES			: 'FETCH_FOLDER_SITES',
	PAGINATE_TEMPLATE_SITES		: 'PAGINATE_TEMPLATE_SITES',
	FETCH_TEMPLATE_SITE			: 'FETCH_TEMPLATE_SITE',
	UPDATE_INUSE_SITE			: 'UPDATE_INUSE_SITE',
	UPDATE_INUSE_FOLDER			: 'UPDATE_INUSE_FOLDER',
	DELETE_INUSE_SITE_IMAGE		: 'DELETE_INUSE_SITE_IMAGE',
	FETCH_PUBLIC_FOLDER			: 'FETCH_PUBLIC_FOLDER',
	FETCH_LEGACY_NEWSLETTER		: 'FETCH_LEGACY_NEWSLETTER'
}

// extend default action types with FULFILLED, PENDING and REJECTED statuses
_.each(RESTfulActionTypes, function(key, val, obj){

	obj[key + '_' + FULFILLED] 	= val + '_' + FULFILLED;
	obj[key + '_' + PENDING] 	= val + '_' + PENDING;
	obj[key + '_' + REJECTED] 	= val + '_' + REJECTED;

});

const actions = {};
const actionTypes = _.merge({
	UPDATE_HOMEPAGE_STATE: 'UPDATE_HOMEPAGE_STATE',
	ADD_UIWINDOW		 : 'ADD_UIWINDOW',
	UPDATE_UIWINDOW		 : 'UPDATE_UIWINDOW',
	DELETE_UIWINDOW		 : 'DELETE_UIWINDOW',
	REMOVE_SITE_LOCAL    : 'REMOVE_SITE_LOCAL',
	SET_DUPLICATING_SITE_ID: 'SET_DUPLICATING_SITE_ID',
	UPDATE_SITE_PREVIEW_STATE: 'UPDATE_SITE_PREVIEW_STATE',
	UPDATE_SITE_LOCAL: 'UPDATE_SITE_LOCAL',
}, RESTfulActionTypes);

/**
 * Actions are defined below:
 */

actions.getAuthToken = function( options ) {

	const promise = new Promise((resolve, reject) => {

		const AUTH_PATH = CARGO_ENV !== "production" ?
			'https://dev.cargo.site/accesstoken' :
			'https://cargo.site/accesstoken';

		const headers = {};

		if(typeof window !== 'undefined' && window.__ACCESS_TOKEN_NONCE__) {
			
			headers['x-cargo-access-token-nonce'] = window.__ACCESS_TOKEN_NONCE__;

			// nonce once
			delete window.__ACCESS_TOKEN_NONCE__;

		}

		cargoFetch.get(AUTH_PATH, {
			withCredentials: true, 
			headers
		})
		.then(function(response) {

			if(!response || response.token === null) {
				return reject('No token found.');
			}

			resolve(response)

		})
		.catch(e => {
			reject(e);
		});

	})

	return {
		type: actionTypes.AUTHENTICATE_USER,
		payload: promise
	}

}

actions.logout = function(meta){

	return function(dispatch, getState) {

		const request = cargoFetch.get(AUTH_ORIGIN + '/logout', {
			withCredentials: true
		});

		if( window.Intercom?.booted ){
			window.Intercom('shutdown');
		}
		

		return dispatch({
			type: actionTypes.LOGOUT_USER,
			payload: request,
			meta
		})
		
	}

}

actions.fetchAccount = function(){

	return function(dispatch, getState) {

		const state = getState();

		const userId = state.auth.data?.id;

		if(!userId) {
			return Promise.reject(`No valid user ID found`);
		}

		let request;

		if(window.__userFetchPromise__) {
			// use the data fetched in index.html
			request = __userFetchPromise__;
			delete window.__userFetchPromise__;
		} else {
			request = cargoFetch.get(`${API_ORIGIN}/users/${userId}/homepage`);
		}

		return dispatch({
			type: actionTypes.FETCH_ACCOUNT,
			payload: request
		})

	}

	
}

actions.updateUser = (changes) => {

	return function(dispatch, getState) {

		const state = getState();
		const userId = state.auth.data?.id;
		
		// https://api.dev.cargo.site/v1/users/{userId}
		return dispatch({
			type: actionTypes.UPDATE_ACCOUNT,
			payload: cargoFetch.put(`${API_ORIGIN}/users/${userId}`, changes)
		});

	};

}

actions.updateUserMeta = function(changes, options){

	return function(dispatch, getState) {

		let request = cargoFetch.put(
			`${API_ORIGIN}/users/${getState().auth?.data?.id}`, {meta: changes}, 
		);

		return dispatch({
			type: actionTypes.UPDATE_USER_META,
			payload: request,
			meta: changes
		})

	}

}


actions.fetchCollaborators = function() {
	// return all the collaborators associated with the user
	return function(dispatch, getState) {
		
		const state = getState();
		const userId = state.auth.data?.id;

		if(!userId) {
			return Promise.reject(`No valid user ID found`);
		}
		// /users/{userId}/collaborators
		let request = cargoFetch.get(`${API_ORIGIN}/users/${userId}/collaborators`);

		return dispatch({
			type: actionTypes.FETCH_COLLAB,
			payload: request
		});

	}

}

actions.fetchFolders = function() {

	return function(dispatch, getState) {

        const state = getState();
        const userId = state.auth.data?.id;
		if(!userId) {
			return Promise.reject(`No valid user ID found`);
		}

		const request = cargoFetch.get(`${API_ORIGIN}/folders/${userId}`);
		
		return dispatch({
			type: actionTypes.FETCH_FOLDERS,
			payload: request
		});

	}

}

actions.fetchSitesInFolder = function(folderId) {

	return function(dispatch, getState) {

		const state = getState();
		const userId = state.auth.data?.id;
		
		if(!userId) {
			return Promise.reject(`No valid user ID found`);
		}

		Sentry.captureMessage('Fetching missing sites', {
			extra: {
				userId: userId,
				folder: folderId
			}
		});

		return dispatch({
			type: actionTypes.FETCH_FOLDER_SITES,
			payload: cargoFetch.get(`${API_ORIGIN}/folders/${userId}/${folderId}/sites`)
		});

	}

}

actions.fetchPublicFolder = function(creatorId, folderSlug) {

	return function(dispatch, getState) {

		if(!creatorId || !folderSlug) {
			return Promise.reject(`No valid folder owner ID or slug found in url`);
		}
		// https://api.dev.cargo.site/v1/folders/${user id of folder owner}/slug/${folder slug}
		const request = cargoFetch.get(`${API_ORIGIN}/folders/${creatorId}/slug/${folderSlug}`);

		return dispatch({
			type: actionTypes.FETCH_PUBLIC_FOLDER,
			payload: request,
			meta : {
				creatorId: creatorId
			}
		});

	}

}

actions.fetchLegacyNewsletterEdition = function(edition) {

	return function(dispatch, getState) {

		const request = cargoFetch.get(`https://cargo.site/_api/v0/site/74/content/${edition}`);

		return dispatch({
			type: actionTypes.FETCH_LEGACY_NEWSLETTER,
			payload: request,
			meta : {
				edition
			}
		});

	}

}

actions.fetchTemplates = function() {

	return function(dispatch, getState) {

		const request = cargoFetch.get(`${API_PROXY_ORIGIN}/templates`);
		
		return dispatch({
			type: actionTypes.FETCH_TEMPLATES,
			payload: request
		});

	}

}

actions.fetchDeletedSites = function() {

	return function(dispatch, getState) {

		const state = getState();
	    const userId = state.auth.data?.id;
	    
		if(!userId) {
			return Promise.reject(`No valid user ID found`);
		}

		let request = cargoFetch.get(`${API_ORIGIN}/users/${userId}/deletedsites`);

		return dispatch({
			type: actionTypes.FETCH_DELETED_SITES,
			payload: request
		});

	}
}

actions.saveFolderSort = function(newSortArray) {

	return function(dispatch, getState) {

        const state = getState();
        const userId = state.auth.data?.id;
        
		if(!userId) {
			return Promise.reject(`No valid user ID found`);
		}
        
		const request = cargoFetch.put(`${API_ORIGIN}/folders/${userId}/sort`, 
			{
				'sort': newSortArray
			}
		);
		
		return dispatch({
			type: actionTypes.SAVE_FOLDER_SORT,
			payload: request,
			meta: newSortArray
		});

	}

}

export const mutateFolderSiteArray = function(folder, site, type, insertAtIndex = 0) {

	let newFolderSitesArray = _.cloneDeep(folder.sites);

	// console.log("Mutate folder before", newFolderSitesArray, site, insertAtIndex)

	// make sure we're sorted the same as in the DOM
	newFolderSitesArray.sort((a,b) => {
		return a.sort - b.sort;
	});

	// make sure there's no dupes
	newFolderSitesArray = _.uniqBy(newFolderSitesArray, 'site_id');

	if( type === 'remove' ){
		// remove the site from this folder
		newFolderSitesArray = newFolderSitesArray.filter(({site_id}) => site_id != site.id);
	} else {
		// insert the site at the requested index
		newFolderSitesArray.splice(insertAtIndex, 0, site);
	}

	// set new sort indexes
	newFolderSitesArray.forEach((site, index) => {
		if(site) {
			site.sort = index;
		}
	});

	return newFolderSitesArray;

}

actions.updateFolderItems = function(folder, site, type, insertAtIndex = 0 ) {

	return function(dispatch, getState) {

		const userPermissions = getState().account.permissions;
		
		const userRole = _.find(userPermissions, (permissionSite) => { return ( permissionSite.site_id === site.site_id || permissionSite.site_id === site.id) });
		if( userRole ){
			site.role = userRole.role;
		}

		return dispatch(
			actions.updateFolder({
				...folder,
				sites: mutateFolderSiteArray(folder, site, type, insertAtIndex)
			})
		);
	}

}

actions.updateFolder = function(folder) {

	return function(dispatch, getState) {

        const state = getState();
        const userId = state.auth.data?.id;

		if(!userId) {
			return Promise.reject(`No valid user ID found`);
		}

		if(folder.sites) {
			// make sure there's no dupes
			folder.sites = _.uniqBy(folder.sites, 'site_id');
		}

		const existingFolder = state.folders.find(existingFolder => existingFolder.id === folder.id);

		// check if name changed, if so: generate a new slug
		if(existingFolder && existingFolder.name !== folder.name) {
			folder.slug = getSlug(folder.name, {
				inUse: _.map(store.getState().folders, 'slug').concat(existingBaseRoutes)
			});
		}
		
		const request = cargoFetch.put(`${API_ORIGIN}/folders/${userId}/${folder.id}`, 
			folder
		);
		
		return dispatch({
			type: actionTypes.UPDATE_FOLDER,
			payload: request,
			meta: {
				folder,
				sitesOwnedByUser: state.sites,
			}
		});

	}

}

actions.createFolder = function( name ) {

	return function( dispatch, getState ) {

        const state = getState();
        const userId = state.auth.data?.id;
        
		if(!userId) {
			return Promise.reject(`No valid user ID found`);
		}

		const sort = state.folders.length;
		const slug = getSlug(name, {
			inUse: _.map(store.getState().folders, 'slug').concat(existingBaseRoutes)
		});

		let promise = cargoFetch.post(`${API_ORIGIN}/folders/${userId}`, 
			{
				name,
				sort,
				slug
			}
		);

		return dispatch({
			type: actionTypes.CREATE_FOLDER,
			payload: promise,
			meta: {
				name
			}
		})
	}

}

actions.deleteFolder = function( id ) {

	return function( dispatch, getState ) {
        const state = getState();
        const userId = state.auth.data?.id;
        
		if(!userId) {
			return Promise.reject(`No valid user ID found`);
		}
        
		let promise = cargoFetch.delete(`${API_ORIGIN}/folders/${userId}/${id}`);

		return dispatch({
			type: actionTypes.DELETE_FOLDER,
			payload: promise,
			meta: {id: id}
		})
	}	

}

actions.updateSite = function(id, data, options) {

	return function(dispatch, getState) {

		if( data == null && options ){
			// Add new folder to site. 
			let state  = getState();
			let folder = _.find( state.folders, ['name', options]);
			let site   = _.find( state.sites, ['id', id]);

			let newArr = [...site.folders, folder.id];
			data = {'folders' : newArr}
		}

		const meta = {
			site_id: id,
			data: data
		}

		let promise = cargoFetch.put(`${API_ORIGIN}/sites/${id}`, data);

		return dispatch({
			type: actionTypes.UPDATE_SITE,
			payload: promise,
			meta: meta,
		});
		
	}

}

actions.duplicateSite = function(site_id, folder, insert_before_this_id = null, offset = 1, withoutModel = false ){

	return function(dispatch, getState) {

		const state = store.getState();
		const templateSites = state.templates ? state.templates.flatMap(template => template.sites) : [];

		if( !folder ){
			// Get ALL folder if no folder is specified 
			folder = state.folders.find((folder)=> { return folder.slug === 'all' });

			folder = folder ? folder : state.account?.root_folder_id

		}

		if( !folder ){
			const request = cargoFetch.post(`${API_ORIGIN}/sites/${site_id}`, {});
			const site = state.sites.find(({id}) => id === site_id) || templateSites.find(({id}) => id === site_id);
			console.warn('No folder found for site duplication. Defaulting to root folder.');
			return dispatch({
				type: actionTypes.DUPLICATE_SITE,
				payload: request,
				meta: {
					site: site
				}
			});
		}

		// find site in folder
		const insertOnModel = folder?.sites.find(site => site.site_id === insert_before_this_id ) || null;
		// Find index of both items in folder.
		const insertOnModelIndex = folder?.sites.findIndex(site => site.site_id === insert_before_this_id ) || 0;
		const originalSiteIndex = folder?.sites.findIndex(site => site.site_id === site_id ) || 0;

		// dont do offset if index of site being dupicated is greater than position of insertion.
		if( originalSiteIndex >= insertOnModelIndex ){
			offset = 0;
		}
		// Add offset to sort derived from folder model.
		let newSort = insertOnModel?.sort + offset || 0; 
		// Add it to the request object.
		let requestObj = { folder_id: folder.id, sort: newSort }

		const request = cargoFetch.post(`${API_ORIGIN}/sites/${site_id}`, requestObj);
		const site = state.sites.find(({id}) => id === site_id) || templateSites.find(({id}) => id === site_id);

		// Account for instances where we want to show a duplicate clone, but don't know what model exactly we're duping.
		// This happens when we duplicate via query string.
		let metaSite = site;

		if( withoutModel ){
			metaSite = state.sites[0];
		}

		if( !metaSite ){
			metaSite = {
			    "id": null,
			    "direct_link": "",
			    "display_url": "",
			    "site_url": "",
			    "domain": null,
			    "website_title": null,
			    "is_private": false,
			    "is_deleted": false,
			    "is_upgraded": false,
			    "is_template": false,
			    "password_enabled": false,
			    "recent_date": null,
			    "version": "Cargo3",
			    "total_file_size": 0,
			    "domain_active": false,
			    "css_url": null,
			    "rss_url": null,
			    "favicon_url": "https://static.cargo.site/favicon/c3-favicon.ico",
			    "has_annex_upgrade": false,
			    "has_commerce_addon": false,
			    "has_storage_addon": false,
			    "screenshot": null
			}
		}
		
		return dispatch({
			type: actionTypes.DUPLICATE_SITE,
			payload: request,
			meta: {
				folder,
				insert_before_this_id,
				offset,
				site: metaSite,
				withoutModel: withoutModel
			}
		});
	}
}

// Removes progress ID from C2 site duplication.
actions.confirmDuplication = progressId => ({
	type: actionTypes.CONFIRM_DUPLICATION,
	payload: progressId
});

// Sets C3 site duplication id in redux. Removed by account re-pull.
actions.setDuplicatingSiteId = siteId => ({
	type: actionTypes.SET_DUPLICATING_SITE_ID,
	payload: siteId
});

actions.syncAfterSiteDuplication = function(folder, res) {
	// The purpose of this function is to pull new permissions data for 
	// the newly duplicated site into the account.
	
	// Runs from custom event in the progress bar or site component 
	// after the site is completely finished duplicating. 

	// If run before duplication is complete, it will re-render the sites list
	// and remove the progress bar prematurely. 
	return function(dispatch, getState) {
		// fetch user data because it contains the new permissions
		// for this cloned site -- also wipes account.duplicating from the store.
		dispatch( actions.fetchAccount() )

	}

}


actions.leaveSite = function(siteId) {

	return function(dispatch, getState) {

	    const state = getState();
	    const userId = state.auth.data?.id;
	    
		if(!userId) {
			return Promise.reject(`No valid user ID found`);
		}

		const request = cargoFetch.delete(`${API_ORIGIN}/permissions/${userId}/site/${siteId}`);
		
		return dispatch({
			type: actionTypes.LEAVE_SITE,
			payload: request,
			meta: {
				siteId
			}
		});

	}

}


actions.removeSiteLocally = function(siteId) {
	// Performs a fake delete to remove the site from view before 
	// refetching the user account data.
	return function(dispatch, getState) {

		return dispatch({
			type: actionTypes.REMOVE_SITE_LOCAL,
			meta: {
				siteId
			}
		});

	}

}


actions.deleteSite = function(siteId, options) {

	return function(dispatch, getState) {

		const state   = getState();
		const request = cargoFetch.delete(`${API_ORIGIN}/sites/${siteId}`)
		const site    = state.sites.find((site) => site.id === siteId );
		const siteIsUpgraded = site?.is_upgraded;

		request.then((res) => {
				// Check to see if any non deleted sites are upgraded
				let upgradedSiteIndex = state.sites ? state.sites.findIndex((item)=> item.is_upgraded === true && item.is_deleted === false && item.id !== res.data.id ) : -1;
				let deletedUpgradedSiteIndex = state.deletedSites ? state.deletedSites.findIndex((item)=> item.is_upgraded === true && item.id !== res.data.id ) : -1;
				// If site we just deleted is deleted, is upgraded, site is undefined, and we can't find any other upgraded sites anywhere.
				// This is agnostic to soft delete since the API is not always removing the upgrade until permanent delete, but sometimes it does.
				if( res.data.is_deleted && siteIsUpgraded && upgradedSiteIndex === -1 && deletedUpgradedSiteIndex === -1 ){
					// Re pull all the account data
					const userId = state.auth.data?.id;
					const accRequest = cargoFetch.get(`${API_ORIGIN}/users/${userId}/homepage`)

					return dispatch({
						type: actionTypes.FETCH_ACCOUNT,
						payload: accRequest
					})

				}

		})


		return dispatch({
			type: actionTypes.DELETE_SITE,
			payload: request,
			meta: {
				siteId,
				site
			}
		});

	}
}

	actions.deleteAllSites = function() {
		return function(dispatch, getState){

			const state  = getState();
			const userId = state.auth.data?.id;
			const request = cargoFetch.delete(`${API_ORIGIN}/users/${userId}/deletedsites`);

			request.then((res) => {
					// Check to see if any non deleted sites are upgraded
					let upgradedSiteIndex = state.sites ? state.sites.findIndex((item)=> item.is_upgraded === true && item.is_deleted === false && item.id !== res.data.id ) : -1;
					let deletedUpgradedSiteIndex = state.deletedSites ? state.deletedSites.findIndex((item)=> item.is_upgraded === true ) : -1;
					// If site we just deleted is is upgraded, and we can't find any other upgraded sites...
					if( upgradedSiteIndex === -1 && deletedUpgradedSiteIndex !== -1 ){
						// Re pull all the account data
						const accRequest = cargoFetch.get(`${API_ORIGIN}/users/${userId}/homepage`)

						return dispatch({
							type: actionTypes.FETCH_ACCOUNT,
							payload: accRequest
						})

					}

			})

			return dispatch({
				type: actionTypes.DELETE_ALL_SITES,
				payload: request,
				meta: 'delete-all'
			});

		}
	}

actions.restoreSite = function(siteId, options) {

	return function(dispatch, getState) {

		const request = cargoFetch.put(`${API_ORIGIN}/sites/${siteId}`, { is_deleted : false})

		const state = getState();
		const site  = state.deletedSites.find((site) => site.id === siteId );

		return dispatch({
			type: actionTypes.RESTORE_SITE,
			payload: request,
			meta: { site }
		});

	}
}

actions.fetchSiteSubscriptions = function(options) {

	return function(dispatch, getState) {

		const state = getState();
		// https://dev.cargo.site/_api/v2/subscription/14293764/all
		const request = cargoFetch.get(`${ C2_API_ORIGIN }/v2/subscription/${state.auth.data.id}/all`)

		return dispatch({
			type: actionTypes.FETCH_SITE_SUBSCRIPTIONS,
			payload: request,
		});

	}
}


actions.transferSubscription = function(siteIdTransferTo, siteIdTransferFrom, alert, options) {
	return function(dispatch, getState) {

		const state = getState(); // redux store
		// filter for just site subscriptions.
		const siteSubscriptions = state.subscriptions.filter((subscription)=> { return subscription.type === 'upgrade' })
		// return if there are no subscriptions
		const subscription = siteSubscriptions.find((sub) => { return sub.sites_uid === siteIdTransferFrom })

		if( !subscription ){
			// close modal
			// wait...
			// Show error modal
			return
		}

		const requestObj = { "sites_uid": siteIdTransferTo,
							"interval": subscription.interval,
							"type":"upgrade",
							"addons":[{
								"type":"upgrade",
								"new":true
							}],
							"transfer":true,
							"c1_id":0,
							"c2_id": subscription.sites_uid }

		const request = cargoFetch.post(`${ C2_API_ORIGIN }/v2/subscription/${state.auth.data.id}`, requestObj);

		return dispatch({
			type: actionTypes.TRANSFER_SITE_SUBSCRIPTION,
			payload: request
		});



	}
}

let communityFetchInProgress = null;

actions.fetchCommunityPeriods = function(amount = 4) {

	return function(dispatch, getState) {

		if(communityFetchInProgress) {
			// only run this once at a time
			return communityFetchInProgress;
		}

		const state = getState();
		const offset = state.community.offset || 0;

		if(state.community.paginationComplete) {
			// don't do anything
			return Promise.resolve();
		}

		// const request = cargoFetch.get(`${API_ORIGIN}/work/explore/latest/${offset}/${amount}`);
		const request = cargoFetch.get(`https://api.cargo.site/v1/work/explore/latest/${offset}/${amount}`);

		request.finally(() => {
			communityFetchInProgress = null;
		})

		communityFetchInProgress = dispatch({
			type: actionTypes.FETCH_COMMUNITY_PERIODS,
			payload: request,
			meta: {
				amount
			}
		});

		return communityFetchInProgress;

	}

}

// NONRESTFUL ACTIONS
actions.updateHomepageState = function(changes) {

	return function(dispatch, getState){
		return dispatch({
			type: actionTypes.UPDATE_HOMEPAGE_STATE,
			payload: changes,
		});

	}

}

actions.updateSitePreview = function(changes) {

	// previewingSite: false,
	// previewSiteModel: null,
	// parentFolderId: null,
	let newObj = changes; 
	if( !changes ){
		newObj = {
			previewingSite: false,
			previewSiteModel: null,
			containingFolderID: null,
		}
	}

	return function(dispatch, getState){
		return dispatch({
			type: actionTypes.UPDATE_SITE_PREVIEW_STATE,
			payload: newObj,
		});

	}

}

actions.updateSiteLocal = function(id, data, options) {

	return function(dispatch, getState) {

		const meta = {
			site_id: id,
			data: data
		}

		return dispatch({
			type: actionTypes.UPDATE_SITE_LOCAL,
			meta: meta,
		});
	}
}

actions.addUIWindow = function(uiWindow, options){

	return function(dispatch, getState) {
		
		uiWindow = _.defaults(uiWindow, {
			// group the uiWindow will be added to
			group: 'main',
			// options passed to the uiWindow's component
			props: {}
		});

		options = _.defaults(options, {
			// close a uiWindow when it's already opened.
			toggle: true,
			// close all others in the group
			removeGroup: true,
			// close all other uiWindows in all groups
			removeAll: false
		})

		// generate an id if none is passed.
		if(!uiWindow.id) {
			uiWindow.id = `${uiWindow.component}@${uiWindow.group}`
		}

		const state = getState();

		// If toggle is enabled, we need to check if an instance of this uiWindow already exists. 
		if(options.toggle) {
			if(state.uiWindows.byId[uiWindow.id]) {
				return dispatch(
					actions.removeUIWindow(uiWindow.id)
				);
			}

		}

		if(options.removeAll) {
			
			// remove all uiWindows
			state.uiWindows.byId.forEach(uiWindowID => {
				dispatch(
					actions.removeUIWindow(uiWindowID)
				);
			});

		} else if(options.removeGroup) {

			// remove all uiWindows in the same group as the new uiWindow
			if(state.uiWindows.byGroup[uiWindow.group]) {
				state.uiWindows.byGroup[uiWindow.group].forEach(uiWindowID => {
					dispatch(
						actions.removeUIWindow(uiWindowID)
					);
				});
			}

		}

		return dispatch({
			type: actionTypes.ADD_UIWINDOW,
			payload: uiWindow
		});

	}

}

actions.updateUIWindow = function(uiWindowID, propsToMerge){

	return function(dispatch, getState) {

		return dispatch({
			type: actionTypes.UPDATE_UIWINDOW,
			payload: propsToMerge,
			meta: {
				id: uiWindowID
			}
		})

	}

}

actions.removeUIWindow = function(idOrFilter, options){

	return function(dispatch, getState) {

		let uiWindows = [];

		if(typeof idOrFilter === "function") {

			uiWindows = _.filter(getState().uiWindows.byId, idOrFilter);

		} else {

			uiWindows.push(getState().uiWindows.byId[idOrFilter]);

			if(!uiWindows[0]) {
				return Promise.reject(`UIWindow with id '${idOrFilter}' does not exist`);
			}

		}
		
		if( uiWindows?.[0]?.id ==='c3-account-manager-window' ){
			let processingTransaction = document.querySelector('.payment-button .submit-payment.processing-transaction');
			if( processingTransaction ) return 
		}
		
		return dispatch({
			type: actionTypes.DELETE_UIWINDOW,
			payload: uiWindows
		});

	}

}

actions.updateInUseSite = function(id, data, options) {
	return function(dispatch, getState) {

		let promise = cargoFetch.put(`${API_ORIGIN}/inuse/${id}`, data);
		const meta = {
			site_id: id,
			data: data
		}
		return dispatch({
			type: actionTypes.UPDATE_INUSE_SITE,
			payload: promise,
			meta: meta,
		});
	}
}

actions.deleteInUseImage = function(site_id) {
	return function(dispatch, getState) {

		let promise = cargoFetch.delete(`${API_ORIGIN}/media/${site_id}/inuse/`);

		const meta = {
			site_id: site_id,
			type: 'delete-inuse-image'
		}
		return dispatch({
			type: actionTypes.DELETE_INUSE_SITE_IMAGE,
			payload: promise,
			meta: meta,
		});
	}
}

actions.updateInUseFolder = function( site_id, folder_id, type ) {
	return function(dispatch, getState) {

		let promise = null;
		if( type === 'add' ){
			promise = cargoFetch.post(`${API_ORIGIN}/inuse/${site_id}/template/${folder_id}`);
		}
		if( type === 'remove' ){
			promise = cargoFetch.delete(`${API_ORIGIN}/inuse/${site_id}/template/${folder_id}`);
		}

		return dispatch({
			type: actionTypes.UPDATE_INUSE_FOLDER,
			payload: promise,
			meta: {
				site_id: site_id,
				folder_id: folder_id,
				type: type
			},
		})

	}
}

actions.fetchInUseSites = function(){
	return function(dispatch, getState) {
		
		let promise = cargoFetch.get(`${API_ORIGIN}/inuse/${id}`, data);

		return dispatch({
			type: actionTypes.FETCH_INUSE_SITES,
			payload: promise,
		});
	}
}

actions.paginateTemplateSites = function( folder ){
	return function(dispatch, getState){

		let request = cargoFetch.get(`${API_ORIGIN}/templates/${folder.slug}?page=${folder.pagination}`)

		return dispatch({
			type: actionTypes.PAGINATE_TEMPLATE_SITES,
			payload: request,
			meta: {
				folder: folder
			}
		})
	}
}

actions.fetchTemplateSite = function( folder, siteId ){
	return function( dispatch, getState ){

		let request = cargoFetch.get(`${API_ORIGIN}/templates/${folder.slug}/site/${siteId}`)

		return dispatch({
			type: actionTypes.FETCH_TEMPLATE_SITE,
			payload: request,
			meta: {
				folder: folder,
				siteId: siteId
			}
		})

	}
}

export {
	actionTypes,
	actions
}